import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';

const StyledTestimonialSection = styled.section`
  max-width: 650px;
  margin: 0 auto 0px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 0px;
  }

  .subtitle {
    margin-bottom: 4em;
    // font-size: clamp(40px, 5vw, 60px);
  }
`;

const StyledTestimonialsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledTestimonial = styled.li`
  @media (max-width: 768px) {
    margin: 0 auto 0px;
  }   

  .testimonial-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .testimonial-name {
    // margin: 20px 0 5px 0px;
    color: var(--lightest-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 600;
  }

  .testimonial-company {
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .testimonial-description {
    margin: 30px 0 0px 0px;
    position: relative;
    z-index: 2;
    padding: 5px;    
    color: var(--light-slate);
    font-size: var(--fz-lg);

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }   
  }

  .testimonial-image {
    .img {
      border-radius: var(--border-radius);
      opacity: 0.7;
      display: block;
      width: 80px;
      height: 80px;
      margin: auto;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 70px;
        height: 70px;
      }
    }
  }
`;

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    {
      testimonial: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/testimonial/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              date
              name
              photo {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              company
              position
            }
            html
          }
        }
      }
    }
  `);

  const testimonialDatas = data.testimonial.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealTestimonials = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealTestimonials.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <StyledTestimonialSection id="testimonial">
      <h2 ref={revealTitle}>Testimonial</h2>
      <p className="subtitle">People I've worked with have said ...</p>

      <StyledTestimonialsGrid>
        {testimonialDatas &&
          testimonialDatas.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { name, photo, company, position } = frontmatter;
            const image = getImage(photo);

            return (
              <StyledTestimonial key={i} ref={el => (revealTestimonials.current[i] = el)}>
                <div className="testimonial-content">
                  <div className="testimonial-image">
                    <a href="/testimonial_Paul.pdf" target="_blank" rel="noreferrer">
                      <GatsbyImage image={image} alt={name} className="img" />
                    </a>
                  </div>

                  <div>
                    <div
                      className="testimonial-description"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                    <a href="/testimonial_Paul.pdf" target="_blank" rel="noreferrer">
                      <span className="testimonial-name">- {name} </span>
                      <span className="testimonial-company">
                        / {company} / {position}
                      </span>
                    </a>
                  </div>
                </div>
              </StyledTestimonial>
            );
          })}
      </StyledTestimonialsGrid>
    </StyledTestimonialSection>
  );
};

export default Testimonial;
